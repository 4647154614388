
.no-border-top {
    &,
    * {
        border-top: none !important;
    }
}
.modify-description-table {
    thead th {
        background-color: #f7f7f7 !important;
    }
}
